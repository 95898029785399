import { useFeeDiscountList } from "@gocardless/api/dashboard/fee-discount";

import { ToTranslate } from "../i18n";

export enum IncrementalDiscountType {
  CREATE_ACCOUNT = "30days_free_fee_activation_create_account",
  COMPLETE_SETUP_15 = "15days_free_fee_activation_complete_setup",
  COMPLETE_SETUP_30 = "30days_free_fee_activation_complete_setup",
  COMPLETE_SETUP_EXTENSION = "15days_free_fee_activation_complete_setup_extension",
  ADD_FIRST_CUSTOMER = "30days_free_fee_activation_add_first_customer",
  COLLECT_FIRST_PAYMENT = "30days_free_fee_activation_collect_first_payment",
  UPLOAD_DOCUMENT = "10days_free_fee_activation_upload_document",
}

export interface RewardListItem {
  id: IncrementalDiscountType;
  title: JSX.Element;
  description: JSX.Element;
  completed: boolean;
}

const rewardsListConfig: RewardListItem[] = [
  {
    id: IncrementalDiscountType.CREATE_ACCOUNT,
    title: <ToTranslate>Create an account</ToTranslate>,
    description: <ToTranslate>+30 days without fees!</ToTranslate>,
    completed: false,
  },
  {
    id: IncrementalDiscountType.COMPLETE_SETUP_30,
    title: (
      <ToTranslate>Fill in your details to set up your account</ToTranslate>
    ),
    description: <ToTranslate>Unlock +30 extra days without fees!</ToTranslate>,
    completed: false,
  },
  {
    id: IncrementalDiscountType.ADD_FIRST_CUSTOMER,
    title: <ToTranslate>Get your first customer</ToTranslate>,
    description: <ToTranslate>Unlock +30 extra days without fees!</ToTranslate>,
    completed: false,
  },
  {
    id: IncrementalDiscountType.COLLECT_FIRST_PAYMENT,
    title: <ToTranslate>Collect your first payment</ToTranslate>,
    description: <ToTranslate>Unlock +30 extra days without fees!</ToTranslate>,
    completed: false,
  },
];

export const useRewardsList = () => {
  const { data: discountData } = useFeeDiscountList();

  const checkDiscount = (id: string) => {
    const discount = discountData?.fee_discounts?.some(
      (d) =>
        d?.discount_type === id &&
        d?.end_date &&
        Date.parse(d.end_date) >= Date.now()
    );
    return Boolean(discount);
  };

  const rewardsList = rewardsListConfig.map((reward) => ({
    ...reward,
    completed: checkDiscount(reward.id),
  }));

  return {
    rewardsList,
  };
};
