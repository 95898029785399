import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  Dialog,
  FontWeight,
  Glyph,
  Icon,
  Interpose,
  JustifyContent,
  P,
  PlainButton,
  PlainLink,
  Separator,
  Space,
  Text,
  TextAlign,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import Image from "next/image";
import RewardDiamondIcon from "src/assets/png/reward-diamond@2x.png";

import { ToTranslate } from "../i18n";
import { useActivationDiscount } from "../routes/Setup/common/components/ActivationDiscount/useActivationDiscount";

import { RewardListItem } from "./RewardListItem";
import { useRewardsList } from "./useRewardsList";

interface RewardsModalProps {
  open: boolean;
  onClose: () => void;
}

export const RewardsModal: React.FC<RewardsModalProps> = ({
  open,
  onClose,
}) => {
  const { remainingDays } = useActivationDiscount();
  const { rewardsList } = useRewardsList();

  return (
    <Box>
      <Dialog
        as="div"
        open={open}
        closeAction={{
          label: <Trans id="Close">Close</Trans>,
          onClose,
        }}
        bodyGutterV={0}
        header={{
          content: (
            <Box>
              <Box
                layout="flex"
                justifyContent={JustifyContent.SpaceBetween}
                alignItems={AlignItems.Center}
                spaceAbove={2}
              >
                <Box>
                  <Box
                    bg={Color.Ultraviolet_50}
                    gutterH={1}
                    gutterV={0.25}
                    spaceBelow={1}
                    borderRadius={0.25}
                    width="154px"
                  >
                    <P
                      size={2}
                      weight={FontWeight.SemiBold}
                      textAlign={TextAlign.Center}
                    >
                      <ToTranslate>Limited time offer</ToTranslate>
                    </P>
                  </Box>
                  <Text preset={TypePreset.Heading_04}>
                    <ToTranslate>Unlock extra days without fees!</ToTranslate>
                  </Text>
                </Box>
                <Image
                  src={RewardDiamondIcon}
                  alt="Diamond icon"
                  width={47}
                  height={60}
                />
              </Box>
              <P
                preset={TypePreset.Body_01}
                color={ColorPreset.TextOnLight_02}
                spaceAbove={1}
              >
                <ToTranslate>
                  You can earn up to 120 days without fees by setting up
                  GoCardless and using it to get paid! Complete the action below
                  within next 30 days to unlock more fees.
                </ToTranslate>
              </P>
            </Box>
          ),
          separator: false,
          gutterV: 2,
        }}
      >
        <Box
          bg={Color.Ultraviolet_700}
          gutterV={0.75}
          spaceBelow={1}
          borderRadius={0.5}
        >
          <P
            textAlign={TextAlign.Center}
            preset={TypePreset.Body_01}
            color={Color.White}
            weight={FontWeight.SemiBold}
          >
            <ToTranslate>
              You currently have {remainingDays} days left with no fees
            </ToTranslate>
          </P>
        </Box>
        <Box
          borderWidth={1}
          borderRadius={1}
          borderColor={ColorPreset.BorderOnLight_03}
          gutterV={1}
        >
          <Interpose
            node={
              <Box spaceAbove={1} spaceBelow={1}>
                <Separator color={ColorPreset.BorderOnLight_03} />
              </Box>
            }
          >
            {rewardsList.map((reward, index) => (
              <RewardListItem
                key={index}
                completed={reward.completed}
                title={reward.title}
                rewardDescription={reward.description}
              />
            ))}
          </Interpose>
        </Box>
        <P
          preset={TypePreset.Body_01}
          color={ColorPreset.TextOnLight_02}
          spaceAbove={2}
        >
          <ToTranslate>
            Transaction fees will be free for this period up to a total volume
            of £50,000.
          </ToTranslate>{" "}
          <PlainLink href="/" target="_blank" decoration="underline">
            <ToTranslate>Terms and conditions apply.</ToTranslate>
          </PlainLink>
        </P>
        <Box
          layout="flex"
          spaceAbove={2}
          spaceBelow={2}
          alignItems={AlignItems.Center}
        >
          <PlainButton>
            <Icon name={Glyph.Tooltip} size="12px" />
          </PlainButton>
          <Space layout="inline" h={0.5} />
          <P preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            How much will it cost after the no-fee period?
          </P>
        </Box>
      </Dialog>
    </Box>
  );
};
